import React from "react";
import { Checkbox } from "antd";
const FilterCard = ({ data, name, onCheckChange }) => {
  return (
    <>
      <div className="filter-card">
        <div className="filter-card-heading">
          <p className="p-3 radio-value">{`Filter by ${name}`}</p>
        </div>
        {data?.map((x, i) => {
          return (
            <div key={i} className="p-3 filter-value">
              <p className="m-0 disabilities-text">
                {x?.filter_title}
              </p>
              <Checkbox
                onChange={(e) =>
                  onCheckChange(
                    e.target.checked, x?.filter_id, name
                  )
                }
                className="foo"
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FilterCard;