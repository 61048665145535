import axios from "axios";
import { Base_Route } from "../constant";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const Auth = localStorage.getItem("AuthUser");
    if (Auth !== null) {
      config.headers = JSON.parse(localStorage.getItem("AuthUser"));
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response?.data?.responsecode === 101) {
      localStorage.removeItem("AuthUser");
      localStorage.removeItem("UserProfile");
      return (window.location.href = Base_Route);
    } else {
      return response;
    }
  },
  (error) => {
    return error;
  }
);

export default instance;
