import { useState, useEffect } from "react";
import Inputfield from "../../../components/ui/Inputfield";
import ButtonData from "../../../components/ui/button";
import Workout from "../../../components/ui/Workout";
import axios from "../../../helper/api/api_instance";
import toastr from "toastr";
import Spinner from "../../../components/ui/spinner";
import FormModal from "../../../components/modals/FormModal";
import SuccessModal from "../../../components/modals/SuccessModal";
const JoinGroup = () => {
  const [inviteCode, setInviteCode] = useState("");
  const [group, setGroup] = useState([]);
  const [groupLoading, setGroupLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [workoutId, setWorkoutId] = useState("");
  const [visible, setVisible] = useState(false);
  const user_type = JSON.parse(localStorage.getItem("UserProfile"))?.UserProfile
    ?.user_type;

  useEffect(() => {
    getAllGroups();
  }, []);

  const getAllGroups = async () => {
    setGroupLoading(true);
    const fd = new FormData();
    fd.append("user_id", JSON.parse(localStorage.getItem("AuthUser")).UserId);
    axios
      .post(`group_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setGroup(res?.data?.data);
          setGroupLoading(false);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const JoinSingleGroup = (invite, value) => {
    if (value === "by_btn") {
      setLoading(true);
    }
    const fd = new FormData();
    fd.append("invite_code", invite);
    axios
      .post(`join_group_send_request`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setLoading(false);
          setInviteCode("");
          setVisible(true);
          setInviteLoading(false);
        } else {
          toastr.error(res.data.message);
          setLoading(false);
          setInviteLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
        setInviteLoading(false);
      });
  };

  const onJoinGroup = (code, id) => {
    setWorkoutId(id);
    JoinSingleGroup(code, "by_btn");
  };

  const handlerJoinGroup = (e) => {
    e.preventDefault();
    if (inviteCode.length !== 0) {
      setInviteLoading(true);
      JoinSingleGroup(inviteCode, "by_field");
    }
  };

  return (
    <>
      <div>
        <div style={{ paddingTop: "25px" }}>
          <p className="activity">
            {user_type === "coach" ? "Groups" : "Teams"}
          </p>
          <div className="mt-3 join-group-form-div">
            <p className="join-group-heading">Join Group</p>
            <div className="d-flex justify-content-between">
              <Inputfield
                onChange={(e) => setInviteCode(e.target.value)}
                placeholder="Enter Invite Code"
                className="join-group-field"
              />
              <ButtonData
                type="join-group-btn"
                loading={inviteLoading}
                onClick={handlerJoinGroup}
              >
                Join
              </ButtonData>
            </div>
          </div>
          <p className="activity mt-4">Group List</p>
          {groupLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner color="#d9d9d9" />
            </div>
          ) : (
            <div className="row">
              {group?.map((x, i) => {
                return (
                  <div key={i} className="col-md-6">
                    <Workout
                      id={x.group_id}
                      title={x.group_name}
                      description={x.group_description}
                      code={x.invite_code}
                      type="group"
                      onClickButton={onJoinGroup}
                      selectedWorkout={workoutId}
                      loading={loading}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <FormModal
        visible={visible}
        onCancel={() => setVisible(false)}
        content={
          <SuccessModal
            message="Request Sent Successfully!"
            setVisible={setVisible}
          />
        }
      />
    </>
  );
};

export default JoinGroup;
