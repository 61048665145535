import { Select } from "antd";
import React from "react";

const AssignExercise = ({
  id,
  name,
  set,
  adjustment,
  description,
  handleChange,
}) => {
  const adjustmentValue = adjustment?.map((x, i) => {
    return { value: x.exercise_adjustment_id, label: x.adjustments_name };
  });

  const repsValue = set?.map((x, i) => {
    return { value: x.exercise_set_id, label: x.set_name };
  });

  return (
    <>
      <div className="select-exercise-item">
        <div className="slct-exrcse-itm-title-container">
          <div className="slct-exrcse-itm-title">{name}</div>
          <div className="slct-exrcse-itm-description">{description}</div>
        </div>

        <Select
          defaultValue="Select Adjustment"
          className="select-dropdown"
          onChange={(val) => handleChange(val, id, "adj_id")}
          options={adjustmentValue}
        />
        <Select
          defaultValue="Select Reps"
          className="select-dropdown"
          onChange={(val) => handleChange(val, id, "set_id")}
          options={repsValue}
        />
      </div>
    </>
  );
};

export default AssignExercise;
