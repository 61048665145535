import { useEffect, useState } from "react";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import ButtonData from "../../../components/ui/button";
import GroupCard from "../../../components/ui/groupCard";
import axios from "../../../helper/api/api_instance";
import Spinner from "../../../components/ui/spinner";
import Athlete from "../../../components/ui/Athlete";
import FormModal from "../../../components/modals/FormModal";
import { Base_Route } from "../../../helper/constant";
import CreateGroup from "../../../components/ui/CreateGroup";
import Slider from "../../../components/ui/Slider";
const Group = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState();
  const [group, setGroup] = useState([]);
  const [groupLoading, setGroupLoading] = useState(false);
  const [groupDetail, setGroupDetail] = useState({});
  const [groupDetailLoading, setGroupDetailLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState(0);
  const [createloading, setCreateLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invite, setInvite] = useState("");
  const checkFocus = localStorage.getItem("Focus");
  const coaches = groupDetail?.user_list?.filter(
    (x) => x.user_type === "coach"
  );
  const athletes = groupDetail?.user_list?.filter(
    (x) => x.user_type === "athlete"
  );

  useEffect(() => {
    getAllGroups();
  }, []);
  const getAllGroups = () => {
    setGroupLoading(true);
    axios
      .post(`user_profile`)
      .then(async (res) => {
        setGroupLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          if(checkFocus !== null){
            setSelected(JSON.parse(checkFocus)?.group_id);
            getGroupDetail(JSON.parse(checkFocus)?.group_id);
            localStorage.removeItem('Focus');
          }else{
            setSelected(res?.data?.data?.group_list[0]?.group_id);
            getGroupDetail(res?.data?.data?.group_list[0]?.group_id);
          }
          setGroup(res?.data?.data?.group_list);
        } 
      })
      .catch((error) => {
        setGroupLoading(false);
        toastr.error(error.message);
      });
  };

  const getGroupDetail = (id) => {
    setGroupDetailLoading(true);
    const fd = new FormData();
    fd.append("group_id", id);
    axios
      .post(`group_details`, fd)
      .then((res) => {
        setGroupDetailLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setGroupDetail(res?.data?.data);
        }
      })
      .catch((error) => {
        setGroupDetailLoading(false);
        toastr.error(error.message);
      });
  };

  const createGroup = () => {
    setCreateLoading(true);
    axios
      .post(`generate_invite_code`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setInvite(res?.data?.data?.invite_code);
          setCreateLoading(false);
          setVisible(true);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const onSelectGroup = (id) => {
    setSelected(id);
    getGroupDetail(id);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onFinish = (values) => {
    setLoading(true);
    const fd = new FormData();
    fd.append(
      "group_type",
      values?.radioGroup === 1 ? "is_private" : "is_public"
    );
    fd.append("group_name", values?.name);
    fd.append("group_description", values?.groupDescription);
    fd.append("invite_code", invite);
    axios
      .post(`create_group`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setGroup([res?.data?.data, ...group]);
          setLoading(false);
          handleCancel();
          toastr.success(`Group Created successfully`);
        } else {
          toastr.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };
  const openDetailHandler = (id, type, profile, name) => {
    if (type === "coach") {
      navigate(`${Base_Route}/trufit/coach-profile`, {
        state: { id, profile, name, selected },
      });
    } else {
      navigate(`${Base_Route}/trufit/athlete-profile`, { state: { id, selected } });
    }
  };

  return (
    <>
      <div>
        <div style={{ marginRight: "20px", paddingTop: "25px" }}>
          <div className="d-flex justify-content-between ms-3">
            <p className="activity m-0">Groups</p>
            <div className="d-flex">
              <ButtonData
                type="join-btn mx-2"
                onClick={() => navigate(`${Base_Route}/trufit/join-group`)}
                style={{ width: "150px" }}
              >
                Join Group
              </ButtonData>
              <ButtonData
                type="assign-btn"
                loading={createloading}
                onClick={() => createGroup()}
                style={{ width: "150px" }}
              >
                Create Group
              </ButtonData>
            </div>
          </div>
          {groupLoading ? (
            <div
              style={{
                marginTop: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Spinner color="#d9d9d9" />
            </div>
          ) : group.length !== 0 ? (
            <Slider>
              {group.map((x, i) => {
                return (
                  <GroupCard
                    key={i}
                    title={x.group_name}
                    description={x.group_description}
                    status={x.group_admin_status}
                    id={x.group_id}
                    selected={selected}
                    onSelectGroup={onSelectGroup}
                  />
                );
              })}
            </Slider>
          ) : (
            <p className="tips-desc mt-4" style={{ textAlign: "center" }}>
              {" "}
              No Groups yet
            </p>
          )}
          {groupDetailLoading ? (
            <div className="mt-5 d-flex justify-content-center">
              <Spinner color="#d9d9d9" />
            </div>
          ) : groupDetail ? (
            <>
              <div className=" mx-3 mt-5 d-flex justify-content-between align-items-center">
                <p className="tips-heading">{groupDetail?.group_name}</p>
                {groupDetail?.group_type === "is_private" && (
                  <p className="invite-code-heading">
                    Invite Code:{" "}
                    <span className="invite-code">
                      {groupDetail?.invite_code}
                    </span>
                  </p>
                )}
              </div>
              <p className="tips-desc mx-3">{groupDetail?.group_description}</p>

              <div className="mx-3">
                <p className="coaches mt-3">Coaches</p>
                <div className="row">
                  {coaches?.length > 0 ? (
                    coaches?.map((x, i) => {
                      return (
                        <div key={i} className="col-md-6">
                          <Athlete
                            profile={x.profile_pic}
                            name={x.user_name}
                            id={x.user_id}
                            type={x.user_type}
                            openDetailHandler={openDetailHandler}
                            classname={"athlete-group-card"}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="d-flex justify-content-center tips-desc">
                      {groupDetailLoading ? (
                        <Spinner color="#d9d9d9" />
                      ) : (
                        <p>No Coach Found</p>
                      )}
                    </div>
                  )}
                </div>
                <p className="coaches mt-3">Athletes</p>
                <div className="row">
                  {athletes?.length > 0 ? (
                    athletes?.map((x, i) => {
                      return (
                        <div key={i} className="col-md-6">
                          <Athlete
                            profile={x.profile_pic}
                            name={x.user_name}
                            id={x.user_id}
                            type={x.user_type}
                            openDetailHandler={openDetailHandler}
                            classname={"athlete-group-card"}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="d-flex justify-content-center tips-desc">
                      {groupDetailLoading ? (
                        <Spinner color="#d9d9d9" />
                      ) : (
                        <p>No Athlete Found</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <span className="d-flex align-items-center justify-content-center view-more pt-3 pb-1"></span>
          )}
        </div>
      </div>
      <FormModal
        title="Create A Group"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        content={
          <CreateGroup
            handleCancel={handleCancel}
            onFinish={onFinish}
            onChange={onChange}
            value={value}
            invite={invite}
            loading={loading}
          />
        }
      />
    </>
  );
};

export default Group;
