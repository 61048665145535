import { Modal } from "antd";
import React from "react";

const AlertModal = ({
  title,
  content,
  visible,
  onOk,
  onCancel,
  destroy
}) => {
  return (
    <Modal
    destroyOnClose={ destroy === 'yes' ? false : true}
    style={{ minWidth:'300px'}}
    className="form-modal"
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      {content}
    </Modal>
  );
};

export default AlertModal;
