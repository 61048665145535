import React, { useEffect, useState } from "react";
import ButtonData from "../../../components/ui/button";
import Inputfield from "../../../components/ui/Inputfield";
import TextArea from "antd/es/input/TextArea";
import ExerciseLogo from "../../../assets/images/icons/icn_add_exercises.png";
import { Checkbox } from "antd";
import ExerciseModalCard from "../../../components/modals/ExerciseModalCard";
import FormModal from "../../../components/modals/FormModal";
import axios from "../../../helper/api/api_instance";
import toastr from "toastr";
import { useLocation, useNavigate } from "react-router";
import ProfileWorkout from "../../../components/ui/ProfileWorkout";

const AddWorkout = () => {
  const [visible, setVisible] = useState(false);
  const [addFeed, setAddFeed] = useState(false);
  const [checkedExercises, setCheckedExercises] = useState([]);
  const [exerciseLoading, setExerciseLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [assignExercise, setAssignExercise] = useState([]);
  const [values, setValues] = useState({ workout: "", description: "" });

  const location = useLocation();
  const navigate = useNavigate();

  let { label, workout } = location?.state;

  useEffect(() => {
    if (label === "edit") {
      setAssignExercise(workout?.exercise_id.split(",").map(Number));
      setValues({
        ...values,
        workout: workout?.workout_title,
        description: workout?.long_desc,
      });
      getworkoutExercises();
    }
  }, []);
  const getworkoutExercises = () => {
    const fd = new FormData();
    fd.append("workout_id", workout?.workout_id);
    axios
      .post(`exercise_list`, fd)
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setCheckedExercises(res?.data?.data);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const getAllExercisesList = (id, name) => {
    setExerciseLoading(true);
    const fd = new FormData();
    if (name === "by_filter") {
      fd.append("filter_disability_id", id?.ability);
      fd.append("filter_focus_id", id?.equipment);
      fd.append("filter_equipment_id", id?.focus);
    } else {
    }
    axios
      .post(`ability_wise_exercise_list`, fd)
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setVisible(true);
          setExercises(res?.data?.data);
          setExerciseLoading(false);
        } else {
          toastr.error(res.data.message);
          setExerciseLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setExerciseLoading(false);
      });
  };

  const onCheckedHandler = (e, val, x) => {
    if (e.target.checked === true) {
      setCheckedExercises([...checkedExercises, x]);
      setAssignExercise([...assignExercise, val]);
    } else {
      setAssignExercise(assignExercise.filter((x) => x !== val));
      setCheckedExercises(
        checkedExercises.filter((x) => x.exercise_id !== val)
      );
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = () => {
    if (
      assignExercise.length !== 0 &&
      values?.workout !== "" &&
      values?.description !== ""
    ) {
      setLoading(true);
      const fd = new FormData();
      if (label !== "edit") {
        fd.append("workout_title", values?.workout);
        fd.append("long_desc", values?.description);
        fd.append("exercise_id", assignExercise?.toString());
        fd.append("workout_status", "is_noramal");
        if (addFeed === true) {
          fd.append("is_feed", "add_feed");
        }
      } else {
        fd.append("workout_id", workout?.workout_id);
        fd.append("workout_title", values?.workout);
        fd.append("long_desc", values?.description);
        fd.append("exercise_id", assignExercise?.toString());
      }
      axios
        .post(`${label !== "edit" ? "create_workout" : "edit_workout"}`, fd)
        .then(async (res) => {
          if (res?.status === 200 && res?.data?.responsecode === 1) {
            navigate(-1);
            setLoading(false);
          } else {
            toastr.error(res.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
          setLoading(false);
        });
    } else {
      toastr.error("Please fill the whole Form");
    }
  };

  const handleRemoveExercise = (id) => {
    setAssignExercise(assignExercise.filter((x) => x !== id));
    setCheckedExercises(checkedExercises.filter((x) => x.exercise_id !== id));
  };

  return (
    <>
      <div className="m-4">
        <div style={{ marginRight: "20px" }}>
          <p className="activity">Create a Workout</p>
          <div className="row mt-5">
            <div className="col-sm-6">
              <Inputfield
                className="login-input"
                placeholder="Enter Workout Name"
                defaultValue={workout?.workout_title}
                onChange={(e) =>
                  setValues({ ...values, workout: e.target.value })
                }
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-sm-6">
              <TextArea
                className="login-input"
                placeholder="Enter Description"
                defaultValue={workout?.long_desc}
                onChange={(e) =>
                  setValues({ ...values, description: e.target.value })
                }
                autoSize={{ minRows: 3, maxRows: 3 }}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <p className="workout-heading-detail">Exercises</p>

            <ButtonData
              onClick={() => getAllExercisesList()}
              type="border-blue-btn"
              style={{ borderRadius: "32px" }}
              loading={exerciseLoading}
            >
              Add Exercises
            </ButtonData>
          </div>
          {checkedExercises.length === 0 ? (
            <div
              style={{ height: "50vh" }}
              className="d-flex justify-content-center align-items-center flex-column"
            >
              <img
                src={ExerciseLogo}
                alt="exercises-logo"
                style={{ height: "30px" }}
              />
              <p className="exercise-title-desc mt-2">No exercise added yet</p>
            </div>
          ) : (
            <>
              <div className="row">
                {checkedExercises.map((x, i) => {
                  return (
                    <div key={i} className="col-lg-6">
                      <ProfileWorkout
                        id={x.exercise_id}
                        title={x.exercise_name}
                        description={x.sort_description}
                        type={"exercise"}
                        removeExercise={handleRemoveExercise}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <ButtonData
            onClick={onFinish}
            type="mt-3 assign-btn save-workout-btn d-flex justify-content-center align-items-center"
            loading={loading}
          >
            Save Workout
          </ButtonData>
          <div className="save-workout-btn d-flex justify-content-center mt-2">
            <p className="exercise-title-desc">Add to Feed</p>

            <Checkbox
              onChange={(e) => setAddFeed(e.target.checked)}
              className="mx-3 add-to-feed"
            />
          </div>
        </div>
      </div>
      <FormModal
        visible={visible}
        onCancel={handleCancel}
        content={
          <ExerciseModalCard
            exercises={exercises}
            onCheckChange={onCheckedHandler}
            assignExercise={assignExercise}
            handleCancel={() => handleCancel()}
            getExercises={getAllExercisesList}
          />
        }
      />
    </>
  );
};

export default AddWorkout;
