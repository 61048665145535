import React from 'react'

const GroupCard = ({selected, onSelectGroup, title, description, status, id}) => {
  return (
    <>
      <div
              className="group-card ms-3"
              onClick={() => onSelectGroup(id)}
              style={{
                border: selected === id ? "1px solid #0081C6" : "none",
              }}
            >
              <div className="d-flex justify-content-between">
                <p className="group-heading">{title}</p>
                <p className="created">{status === "is_not_admin" ? "Created" : ""}</p>
              </div>
              <p className="exercise-desc">
                {description}
              </p>
            </div>
    </>
  )
}

export default GroupCard;