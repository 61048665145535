import React, { useEffect, useState } from "react";
import ButtonData from "../../../components/ui/button";
import { Base_Route } from "../../../helper/constant";
import { useNavigate } from "react-router-dom";
import { Form, Switch, Upload } from "antd";
import Inputfield from "../../../components/ui/Inputfield";
import profileDp from "../../../assets/images/core/dummy-img.png";
import BlueCamera from "../../../assets/images/icons/icn_camera_blue.png";
import User from "../../../assets/images/icons/icn_profile.png";
import FormModal from "../../../components/modals/FormModal";
import ChangePassword from "../../../components/ui/ChangePassword";
import toastr from "toastr";
import axios from "../../../helper/api/api_instance";
import DeleteAccoutModal from "../../../components/modals/DeleteAccoutModal";
export const Profile = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [abilities, setAbilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [state, setState] = useState({});
  const [abilityArr, setAbilityArr] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [form] = Form.useForm();
  const UserProfile = JSON.parse(
    localStorage.getItem("UserProfile")
  ).UserProfile;

  useEffect(() => {
    getAllAbilities();
    setState({ imageUrl: UserProfile?.profile_pic });
    setFileList([...fileList]);
    setAbilityArr(UserProfile?.disability_id.split(","));
    form.setFieldsValue({
      userName: UserProfile?.user_name,
    });
  }, []);

  const getAllAbilities = () => {
    const fd = new FormData();
    fd.append("user_id", UserProfile?.user_id);
    axios
      .post(`ability_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setAbilities(res?.data?.data);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  const deleteAccountHandler = () => {
    axios
      .post(`user_delete_account`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          localStorage.removeItem("AuthUser");
          localStorage.removeItem("UserProfile");

          navigate(`${Base_Route}`);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const props = {
    action: "",
    onChange: handleChange,
    multiple: false,
  };

  const onChange = (checked, val) => {
    if (checked) {
      abilityArr.push(val.toString());
    } else {
      abilityArr.pop(val.toString());
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("user_name", values?.userName);
    fd.append("disability_id", abilityArr?.toString());
    if (fileList?.length > 0) {
      fd.append(
        "profile_pic",
        fileList?.length === 0 &&
          typeof fileList[fileList.length - 1]?.imageUrl === "string"
          ? ""
          : fileList[fileList.length - 1].originFileObj
      );
    }

    fd.append("user_entity_id", UserProfile?.user_id);

    axios
      .post("edit_user", fd)
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          navigate(`${Base_Route}/trufit/athlete-dashboard`);
          localStorage.setItem(
            "UserProfile",
            JSON.stringify({
              UserProfile: res.data.data,
            })
          );
          setLoading(false);
        } else {
          toastr.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="mt-4 mx-3">
        <div className="d-flex justify-content-between align-items-center">
          <p className="activity">User Profile</p>
          <ButtonData onClick={() => setVisible(true)} type="filled-blue-btn">
            Change Password
          </ButtonData>
        </div>
        <Form
          name="edit-user"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          form={form}
          autoComplete="off"
        >
          <div className="row gx-5 gy-5 mt-4">
            <div className="col-lg-5 d-flex align-items-center justify-content-center">
              <div className="profile-main-div d-flex flex-column align-items-center justify-content-center p-4">
                <img
                  src={state?.imageUrl ? state?.imageUrl : profileDp}
                  alt="profile"
                  width="220px"
                  height="220px"
                  style={{ borderRadius: "50%" }}
                />
                <div
                  className="d-flex justify-content-center mt-3"
                  style={{ width: "60%" }}
                >
                  <Upload
                    {...props}
                    fileList={fileList}
                    accept="image/png, image/jpeg"
                    maxCount={1}
                  >
                    <ButtonData
                      type="border-blue-btn profile-btn"
                      icon={
                        <img
                          src={BlueCamera}
                          alt="blue-camera"
                          style={{ height: "20px" }}
                        />
                      }
                    >
                      Upload
                    </ButtonData>
                  </Upload>
                </div>
                <div className="edit-profile-input-div">
                  <Form.Item
                    name="userName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter user name",
                      },
                    ]}
                  >
                    <Inputfield
                      prefix={
                        <img alt="" src={User} style={{ height: "20px" }} />
                      }
                      placeholder="Enter Name"
                      className="edit-profile-input mt-4"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="profile-disabilities-div d-flex flex-column p-4">
                {abilities?.map((x, i) => {
                  return (
                    <div
                      key={i}
                      className="d-flex justify-content-between align-items-center mt-2"
                      style={{
                        borderBottom: "1px solid rgba(0, 124, 186, 0.15)",
                      }}
                    >
                      <p className="faq-sub-heading m-2">
                        {x?.disability_name}
                      </p>
                      <Switch
                        checkedChildren="✓"
                        unCheckedChildren="+"
                        defaultChecked={UserProfile?.disability_id.includes(
                          x?.disability_id
                        )}
                        onChange={(value) => onChange(value, x?.disability_id)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="d-flex w-50 mt-4 mb-4">
            <ButtonData
              type="assign-btn assign-text mt-3 w-50 px-3"
              style={{ height: "45px" }}
              htmltype="submit"
              loading={loading}
            >
              Save
            </ButtonData>

            <ButtonData
              onClick={() => setDeleteModal(true)}
              type="border-red-btn mt-3 w-50 px-3 mx-3"
            >
              Delete Account
            </ButtonData>
          </div>
        </Form>
      </div>
      <FormModal
        visible={visible}
        onCancel={() => setVisible(false)}
        content={<ChangePassword handleCancel={() => setVisible(false)} />}
      />
      <FormModal
        visible={deleteModal}
        title={"Delete Account"}
        onCancel={() => setDeleteModal(false)}
        content={
          <DeleteAccoutModal
            deleteAccountHandler={deleteAccountHandler}
            setDeleteModal={setDeleteModal}
            deleteModal={deleteModal}
          />
        }
      />
    </>
  );
};
