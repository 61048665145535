import React from "react";
import toastr from "toastr";
import AuthFrame from "../../../components/ui/AuthFrame";
import ForgotLogo from "../../../assets/images/icons/img_forgot_password.png";
import Inputfield from "../../../components/ui/Inputfield";
import EmailLogo from "../../../assets/images/icons/icn_email.png";
import ButtonData from "../../../components/ui/button";
import axios from "../../../helper/api/api_instance";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import { useState } from "react";
import { Base_Route } from "../../../helper/constant";
const Forgot = () => {
  const navigate = useNavigate();
  const MyComp = () => {
    const [loading, setLoading] = useState(false);
    const onFinish = (values) => {
      setLoading(true);
      const fd = new FormData();
      fd.append("user_email_id", values?.email);
      axios
        .post(`forgot_password`, fd)
        .then((res) => {
          if (res?.status === 200 && res?.data?.responsecode === 1) {
            setLoading(false);
            navigate(`${Base_Route}/otp`, { state: { email: values?.email } });
          } else {
            toastr.error(res.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          toastr.error(error.message);
          setLoading(false);
        });
    };

    const handleKeyDown = (e) => {
      if (e.key === " ") {
        e.preventDefault();
      }
    };

    return (
      <>
        <img src={ForgotLogo} alt="forgot-logo" style={{ height: "150px" }} />
        <p className="forgot-heading mt-4 mb-0">Forgot Password</p>
        <p className="login-des mx-4 mt-2" style={{ color: "#0A364B" }}>
          Enter the email address Associated with your account
        </p>
        <div className="mt-4">
          <Form
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid email!",
                  validator: (_, value) => {
                    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Please input your E-mail!");
                    }
                  },
                },
              ]}
            >
              <Inputfield
                prefix={
                  <img alt="" src={EmailLogo} style={{ height: "20px" }} />
                }
                placeholder="Enter Email"
                className="login-input"
                onKeyDown={handleKeyDown}
              />
            </Form.Item>

            <ButtonData
              htmltype="submit"
              type="authButton mt-4"
              loading={loading}
            >
              Send
            </ButtonData>
            <p className="no-accout mt-2">
              Back to&nbsp;
              <strong
                onClick={() => navigate(Base_Route)}
                className="yellow-underline"
              >
                Log in
              </strong>
            </p>
          </Form>
        </div>
      </>
    );
  };
  return (
    <>
      <AuthFrame fields={<MyComp />} />
    </>
  );
};

export default Forgot;
