import "./App.css";
import "./assets/styles/main.scss";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/Authentication/Login/Login";
import Signup from "./pages/Authentication/Signup.js/Signup";
import Forgot from "./pages/Authentication/Forgot/Forgot";
import Otp from "./pages/Authentication/Otp/Otp";
import NewPassword from "./pages/Authentication/NewPassword/NewPassword";
import Layout from "./pages/layout/layout";
import Dashboard from "./pages/Coach/Dashboard/Dashboard";
import Group from "./pages/Coach/Group/Group";
import JoinGroup from "./pages/Coach/Group/JoinGroup";
import CoachProfile from "./pages/Coach/CoachProfile";
import AthleteProfile from "./pages/Athlete/AthleteProfile";
import Workout from "./pages/Coach/Workout/Workout";
import AddWorkout from "./pages/Coach/Workout/AddWorkout";
import Exercises from "./pages/Coach/Exercises/Exercises";
import ExerciseDetail from "./pages/Coach/Exercises/ExerciseDetail";
import Playlist from "./pages/Coach/Playlist/Playlist";
import Notifications from "./pages/Notifications/Notifications";
import { Base_Route } from "./helper/constant";
import FrequentlyAskedQuestions from "./pages/FrequentlyAskedQuestions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AthleteDashboard from "./pages/Athlete/Dashboard/Dashboard";
import Team from "./pages/Athlete/Team/Team";
import AthleteWorkout from "./pages/Athlete/Workout/Workout";
import Favorites from "./pages/Athlete/Favorites/Favorites";
import MyWorkouts from "./pages/Athlete/MyWorkouts/MyWorkouts";
import { Profile } from "./pages/Athlete/Profile/Profile";
import WorkoutDetail from "./components/ui/WorkoutDetail";

function App() {
  const navigate = useNavigate();
  const Auth = localStorage.getItem("AuthToken");
  //   useEffect(() =>{
  // if(!Auth){
  //   navigate(Base_Route);
  // }
  //   },[Auth])

  const user_type = JSON.parse(localStorage.getItem("UserProfile"))?.UserProfile
    ?.user_type;
  return (
    <Routes>
      <Route path={Base_Route}>
        {" "}
        {/* put url base here and nest children routes */}
        <Route index element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="forgot" element={<Forgot />} />
        <Route path="otp" element={<Otp />} />
        <Route path="new-password" element={<NewPassword />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="trufit" element={<Layout is_feed={true} />}>
          {user_type === "coach" && (
            <>
              <Route path="coach-dashboard" element={<Dashboard />} />
              <Route path="group" element={<Group />} />
              <Route path="join-group" element={<JoinGroup />} />
              <Route path="workout" element={<Workout />} />
              <Route path="add-workout" element={<AddWorkout />} />
            </>
          )}

          {user_type === "athlete" && (
            <>
              {/* Athlete */}
              <Route path="athlete-dashboard" element={<AthleteDashboard />} />
              <Route path="team" element={<Team />} />
              <Route path="join-group" element={<JoinGroup />} />
            </>
          )}
        </Route>
        <Route path="trufit" element={<Layout is_feed={false} />}>
          {user_type === "coach" && (
            <>
              <Route path="coach-profile" element={<CoachProfile />} />
              <Route path="athlete-profile" element={<AthleteProfile />} />
              <Route path="exercises" element={<Exercises />} />
              <Route path="exercise-detail" element={<ExerciseDetail />} />
              <Route path="playlists" element={<Playlist />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="faq" element={<FrequentlyAskedQuestions />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="workout-detail" element={<WorkoutDetail />} />
            </>
          )}

          {user_type === "athlete" && (
            <>
              {/* Athlete */}
              <Route path="athlete-workout" element={<AthleteWorkout />} />
              <Route path="coach-profile" element={<CoachProfile />} />
              <Route path="athlete-profile" element={<AthleteProfile />} />
              <Route path="exercises" element={<Exercises />} />
              <Route path="exercise-detail" element={<ExerciseDetail />} />
              <Route path="favorites" element={<Favorites />} />
              <Route path="playlists" element={<Playlist />} />
              <Route path="my-workouts" element={<MyWorkouts />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="faq" element={<FrequentlyAskedQuestions />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="user-profile" element={<Profile />} />
              <Route path="workout-detail" element={<WorkoutDetail />} />
            </>
          )}
        </Route>
      </Route>
      <Route path="/*" element={<Navigate to={Base_Route} />} />{" "}
      {/* navigate to default route if no url matched */}
    </Routes>
  );
}

export default App;
