import React from "react";
import Logo from "../../assets/images/icons/icn_header_logo.png";
import Burger from "../../assets/images/icons/icn_hamburger.png";
import feedBurger from "../../assets/images/icons/icn_feed.png";
import edit from "../../assets/images/icons/icn_edit.png";
import Lock from "../../assets/images/icons/icn_lock.png";
import Faq from "../../assets/images/icons/icn_faqs.png";
import PrivacyPolicy from "../../assets/images/icons/icn_privacy_policy.png";
import InviteFriends from "../../assets/images/icons/icn_invite_friends.png";
import DummyImage from "../../assets/images/core/dummy-img.png";
import Help from "../../assets/images/icons/icn_help.png";
import FormModal from "../modals/FormModal";
import { Dropdown } from "antd";
import { useState } from "react";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import NotificationsDropdown from "./NotificationDropdown";
import { useNavigate } from "react-router-dom";
import { Base_Route } from "../../helper/constant";
import DrawerSider from "../sider/DrawerSider";
import ActivityDrawer from "../ui/ActivityDrawer";
import InviteModal from "../modals/InviteModal";
const Header = ({ collapsed, setCollapsed }) => {
  const [visible, setVisible] = useState(false);
  const [currentState, setCurrentState] = useState("");
  const [open, setOpen] = useState(false);
  const [openFeed, setOpenFeed] = useState(false);
  const navigate = useNavigate();
  const user_type = JSON.parse(localStorage.getItem("UserProfile"))?.UserProfile
    ?.user_type;
  const profilePic = () => {
    let img = DummyImage;
    try {
      let profile = JSON.parse(localStorage.getItem("UserProfile"));
      img = profile?.UserProfile?.profile_pic;
    } catch (e) {
      img = DummyImage;
    }

    if (img === undefined || img === "") {
      img = DummyImage;
    }
    return img;
  };

  const handleUserProfile = () => {
    if (user_type === "coach") {
      handleOpenModal("edit-profile");
    } else {
      navigate(`${Base_Route}/trufit/user-profile`);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={handleUserProfile}
          className="d-flex align-items-center text-decoration-none"
        >
          <img src={edit} alt="edit" style={{ height: "16px" }} />
          <span className="mx-2 dropdown-item">Edit Profile</span>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => handleOpenModal("change-password")}
          className="d-flex align-items-center text-decoration-none"
        >
          <img src={Lock} alt="delete" style={{ height: "18px" }} />
          <span className="mx-2 dropdown-item">Change Password</span>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => navigate(`${Base_Route}/trufit/faq`)}
          className="d-flex align-items-center text-decoration-none"
        >
          <img src={Faq} alt="delete" style={{ height: "16px" }} />
          <span className="mx-2 dropdown-item">FAQs</span>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          onClick={() => navigate(`${Base_Route}/trufit/privacy-policy`)}
          className="d-flex align-items-center text-decoration-none"
        >
          <img src={PrivacyPolicy} alt="delete" style={{ height: "16px" }} />
          <span className="mx-2 dropdown-item">Privacy Policy</span>
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          onClick={() => handleOpenModal("invite-friend")}
          className="d-flex align-items-center text-decoration-none"
        >
          <img src={InviteFriends} alt="delete" style={{ height: "18px" }} />
          <span className="mx-2 dropdown-item">Invite Friends</span>
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div className="d-flex align-items-center text-decoration-none">
          <img src={Help} alt="delete" style={{ height: "16px" }} />
          <span className="mx-2 dropdown-item">Help</span>
        </div>
      ),
    },
  ];

  const handleOpenModal = (val) => {
    setCurrentState(val);
    setVisible(true);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleBurgerClick = () => {
    setCollapsed(!collapsed);
    setOpen(true);
  };
  const handleFeedclick = () => {
    setOpenFeed(true);
  };
  return (
    <>
      <ActivityDrawer setOpenFeed={setOpenFeed} openFeed={openFeed} />
      <DrawerSider open={open} setOpen={setOpen} user_type={user_type} />
      <div className="header">
        <div className="icn_header_logo-div d-flex align-items-center justify-content-center">
          <img
            src={Logo}
            alt="icn_header_logo"
            style={{ height: "55px", cursor: "pointer" }}
            onClick={() =>
              navigate(
                `${Base_Route}/trufit/${
                  user_type === "athlete"
                    ? "athlete-dashboard"
                    : "coach-dashboard"
                }`
              )
            }
          />
        </div>
        <div className="d-flex align-items-center justify-content-start">
          <img
            src={Burger}
            alt="icn_hamburger"
            onClick={handleBurgerClick}
            className="header-burger-btn"
          />
        </div>
        <div className="d-flex align-items-center justify-content-end profile">
          <img
            src={feedBurger}
            alt="icn_feed"
            onClick={handleFeedclick}
            className="activity-burger-btn"
          />
          <NotificationsDropdown />
          <Dropdown
            menu={{
              items,
            }}
            overlayClassName="profile-dropdown"
            trigger={["click"]}
            placement="bottomRight"
            arrow
          >
            <img
              src={profilePic()}
              alt="profile-pic"
              className="mx-4"
              style={{
                height: "50px",
                width: "50px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            />
          </Dropdown>
        </div>
      </div>
      <FormModal
        visible={visible}
        onCancel={handleCancel}
        content={
          currentState === "edit-profile" ? (
            <EditProfile handleCancel={handleCancel} />
          ) : currentState === "change-password" ? (
            <ChangePassword handleCancel={handleCancel} />
          ) : (
            <InviteModal handleCancel={handleCancel} />
          )
        }
      />
    </>
  );
};

export default Header;
