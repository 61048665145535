import React from "react";
import ButtonData from "../ui/button";

const StripeModal = () => {
  const handleClick = () => {
    window.open(process.env.REACT_APP_STRIPE_URL);
  };
  return (
    <>
      <p className="stripe-modal-heading text-center">Get Started Today !</p>
      <div className="stripe-blue-div">
        <p className="basic">Basic</p>

        <p className="created-by-coaches">
          $4.99<span className="dollar-month">/Month</span>
        </p>
        <ul className="un-order-list">
          <li className="exercise-desc-2 mt-2">
            Discover new exercises and Track your Progress!
          </li>
          <li className="exercise-desc-2 mt-2">
            Join a Coach and share Workouts with Friends!
          </li>
          <li className="exercise-desc-2 mt-2">Earn Badges and Rewards!</li>
        </ul>
      </div>
      <div className="d-flex justify-content-center">
        <ButtonData
          type="assign-btn text-center mt-4 w-75"
          style={{ fontSize: "18px" }}
          onClick={handleClick}
        >
          Pay Now
        </ButtonData>
      </div>
    </>
  );
};

export default StripeModal;
