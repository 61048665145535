import StrechGirl from "../assets/images/core/strech.png";
import WorkoutGirl from "../assets/images/core/workout-girl.png";
import RunningGirl from "../assets/images/core/running-girl.png";
import CyclingGirl from "../assets/images/core/cycling-girl.png";
export const Base_Route = '/WebApp_Demo'

export const FavoritesOptions = [
  {
    label: "Workouts",
    value: "workout",
  },
  {
    label: "Exercises",
    value: "exercises",
  },
];

export const FavoriteScreensOptions = [
  {
    label: "Exercises",
    value: "exercises",
  },
  {
    label: "Workouts",
    value: "workout",
  },
];

export const WorkoutOptions = [
  {
    label: "Completed ",
    value: "Completed_Workouts",
  },
  {
    label: "Assigned",
    value: "Assigned_Workouts",
  },
  {
    label: "Favourites",
    value: "Favourites",
  },
];

export const MyWorkoutOptions = [
  {
    label: "Completed ",
    value: "Completed_Workouts",
  },
  {
    label: "Assigned ",
    value: "Assigned_Workouts",
  },
  {
    label: "Saved ",
    value: "Saved_Workouts",
  },
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const favOptions = [
  {
    label: "Exercises",
    value: "exercises",
  },
  {
    label: "Sessions",
    value: "session",
  },
];

export const focusColors = ["#CDEBFD", "#F9EBB3", "#D7FFD0", "#FFD9ED"];
export const focusBorder = ["#0081C6", "#FFE374", "#94FF82", "#FFBEE1"];
export const images = [StrechGirl, WorkoutGirl, RunningGirl, CyclingGirl];


