import React from "react";
import { Menu } from "antd";
import axios from "../../../helper/api/api_instance";
import toastr from "toastr";
import { useEffect } from "react";
import { Base_Route } from "../../../helper/constant";
import HomeWhiteIcon from "../../../assets/images/icons/icn_home_slsctd.png";
import HomeGreyIcon from "../../../assets/images/icons/icn_home_unslctd.png";
import GroupWhiteIcon from "../../../assets/images/icons/icn_groups_slctd.png";
import GroupGreyIcon from "../../../assets/images/icons/icn_groups_unslctd.png";
import TeamWhiteIcon from "../../../assets/images/icons/icn_teams_slctd.png";
import TeamGreyIcon from "../../../assets/images/icons/icn_teams_unslctd.png";
import WorkoutWhiteIcon from "../../../assets/images/icons/icn_workouts_slctd.png";
import WorkoutGreyIcon from "../../../assets/images/icons/icn_workouts_unslctd.png";
import ExerciseWhiteIcon from "../../../assets/images/icons/icn_exercise_slctd.png";
import ExerciseGreyIcon from "../../../assets/images/icons/icn_exercise_unslctd.png";
import PlaylistWhiteIcon from "../../../assets/images/icons/icn_playlists_slctd.png";
import PlaylistGreyIcon from "../../../assets/images/icons/icn_playlists_unslctd.png";
import FavoritesWhiteIcon from "../../../assets/images/icons/icn_favs_slctd.png";
import FavoritesGreyIcon from "../../../assets/images/icons/icn_favs_unslctd.png";
import MyWorkoutWhiteIcon from "../../../assets/images/icons/icn_my_workouts_slctd.png";
import MyWorkoutGreyIcon from "../../../assets/images/icons/icn_my_workouts_unslctd.png";
import LogoutIcon from "../../../assets/images/icons/icn_logout.png";
import Spinner from "../../ui/spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const SiderMenu = ({ setOpen, current }) => {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const user_type = JSON.parse(localStorage.getItem("UserProfile"))?.UserProfile
    ?.user_type;
  const onClick = (e) => {
    if (current) {
      setOpen(false);
    }
    if (e.key === "1" && user_type === "coach") {
      navigate(`${Base_Route}/trufit/coach-dashboard`);
    } else if (e.key === "1" && user_type === "athlete") {
      navigate(`${Base_Route}/trufit/athlete-dashboard`);
    } else if (e.key === "2" && user_type === "coach") {
      navigate(`${Base_Route}/trufit/group`);
    } else if (e.key === "2" && user_type === "athlete") {
      navigate(`${Base_Route}/trufit/team`);
    } else if (e.key === "3" && user_type === "coach") {
      navigate(`${Base_Route}/trufit/workout`);
    } else if (e.key === "3" && user_type === "athlete") {
      navigate(`${Base_Route}/trufit/athlete-workout`);
    } else if (e.key === "4") {
      navigate(`${Base_Route}/trufit/exercises`);
    } else if (e.key === "5") {
      navigate(`${Base_Route}/trufit/playlists`);
    } else if (e.key === "6") {
      navigate(`${Base_Route}/trufit/favorites`);
    } else if (e.key === "7") {
      navigate(`${Base_Route}/trufit/my-workouts`);
    } else if (e.key === "0") {
      onUserLogout();
    }
    setKey(e.key);
  };

  useEffect(() => {
    if (
      location?.pathname === `${Base_Route}/trufit/coach-dashboard` &&
      user_type === "coach"
    ) {
      setKey("1");
    } else if (
      location?.pathname === `${Base_Route}/trufit/athlete-dashboard` &&
      user_type === "athlete"
    ) {
      setKey("1");
    } else if (location?.pathname === `${Base_Route}/trufit/group`) {
      setKey("2");
    } else if (location?.pathname === `${Base_Route}/trufit/team`) {
      setKey("2");
    } else if (location?.pathname === `${Base_Route}/trufit/workout`) {
      setKey("3");
    } else if (location?.pathname === `${Base_Route}/trufit/athlete-workout`) {
      setKey("3");
    } else if (location?.pathname === `${Base_Route}/trufit/exercises`) {
      setKey("4");
    } else if (location?.pathname === `${Base_Route}/trufit/playlists`) {
      setKey("5");
    } else if (location?.pathname === `${Base_Route}/trufit/favorites`) {
      setKey("6");
    } else if (location?.pathname === `${Base_Route}/trufit/my-workouts`) {
      setKey("7");
    }
  }, [location, user_type]);

  const onUserLogout = () => {
    setLoading(true);
    axios
      .post(`user_logout`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setLoading(false);
          localStorage.removeItem("AuthUser");
          localStorage.removeItem("UserProfile");

          navigate(Base_Route);
        } else {
          toastr.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };
  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{ height: "85vh" }}
    >
      <Menu
        onClick={onClick}
        className="siderMenu"
        mode="inline"
        defaultSelectedKeys={["1"]}
        selectedKeys={key}
        items={[
          {
            key: "1",
            icon: (
              <img
                src={key === "1" ? HomeWhiteIcon : HomeGreyIcon}
                className="sider-icon"
                alt="home-icon"
              />
            ),
            label: "Home",
          },
          {
            key: "2",
            icon: (
              <img
                alt="group-icon"
                src={
                  key === "2"
                    ? user_type === "coach"
                      ? GroupWhiteIcon
                      : TeamWhiteIcon
                    : user_type === "athlete"
                    ? TeamGreyIcon
                    : GroupGreyIcon
                }
                className="sider-icon"
              />
            ),
            label: user_type === "coach" ? "Groups" : "Team",
          },
          {
            key: "3",
            icon: (
              <img
                alt="workout-icon"
                src={key === "3" ? WorkoutWhiteIcon : WorkoutGreyIcon}
                className="sider-icon"
              />
            ),
            label: "Workouts",
          },
          {
            key: "4",
            icon: (
              <img
                src={key === "4" ? ExerciseWhiteIcon : ExerciseGreyIcon}
                className="sider-icon"
                alt="exercise-icon"
              />
            ),
            label: "Exercises",
          },
          {
            key: "5",
            icon: (
              <img
                src={key === "5" ? PlaylistWhiteIcon : PlaylistGreyIcon}
                className="sider-icon"
                alt="playlist-icon"
              />
            ),
            label: "Playlists",
          },
          {
            key: "6",
            icon: (
              <img
                src={key === "6" ? FavoritesWhiteIcon : FavoritesGreyIcon}
                className="sider-icon"
                alt="favourite-icon"
              />
            ),
            label: "Favorites",
            className: user_type === "coach" ? "hide-menu-item" : "",
          },
          {
            key: "7",
            icon: (
              <img
                src={key === "7" ? MyWorkoutWhiteIcon : MyWorkoutGreyIcon}
                className="sider-icon"
                alt="favourite-icon"
              />
            ),
            label: "My Workouts",
            className: user_type === "coach" ? "hide-menu-item" : "",
          },
        ]}
      />
      <Menu
        onClick={onClick}
        className="mt-5"
        mode="inline"
        defaultSelectedKeys={["1"]}
        selectedKeys={key}
        items={[
          {
            key: "0",
            icon: (
              <img src={LogoutIcon} className="sider-icon" alt="logout-icon" />
            ),
            label: loading ? <Spinner color="#d9d9d9" /> : "Logout",
          },
        ]}
      />
    </div>
  );
};

export default SiderMenu;
