import React, { useState } from "react";
import toastr from "toastr";
import Inputfield from "../ui/Inputfield";
import axios from "../../helper/api/api_instance";
import { Tabs } from "antd";
import ButtonData from "../ui/button";
import { Checkbox } from "antd";
import TextArea from "antd/es/input/TextArea";
import ModalCross from "../../assets/images/icons/icn_close.png";
import DeleteIcon from "../../assets/images/icons/icn_delete_red.png";
import AthletePic from "../../assets/images/core/Athlete.png";
import StickyBox from "react-sticky-box";
import AssignExercise from "../ui/AssignExercise";
import { useEffect } from "react";

const AssignWorkoutModal = ({
  handleCancel,
  exercises,
  setVisible,
  workoutId,
  workoutDetail,
  name,
  exercise_ids,
}) => {
  const [loading, setLoading] = useState(false);
  const [adjustmentData, setAdjustmentData] = useState([]);
  const [assignUser, setAssignUser] = useState([]);
  const [inputValue, setInputvalue] = useState("");
  const [workoutNote, setWorkoutNote] = useState("");
  const [questions, setQuestions] = useState([]);
  const [tab, setTab] = useState("1");
  const [feed, setFeed] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [athlete, setAthlete] = useState([]);
  const [valArr, setValArr] = useState([]);
  const actionButtonText = "Next";

  const handleChange = (value, id, name) => {
    const checkArr = valArr?.some(
      (item) => item.id === id && item.name === name
    );
    if (checkArr) {
      const indexofObj = valArr?.findIndex(
        (item) => item.id === id && item.name === name
      );
      valArr[indexofObj].value = value;
    } else {
      setValArr([...valArr, { id, name, value }]);
    }
    const adjustments = [...adjustmentData];
    const adjIndex = adjustments.findIndex(({ e_id }) => e_id === id);
    if (adjIndex > -1) {
      adjustments[adjIndex][name] = value;
    } else {
      adjustments.push({ e_id: id, [name]: value });
    }
    setAdjustmentData([...adjustments]);
  };

  const onCheckChange = (e, val) => {
    if (e.target.checked === true) {
      setAssignUser([...assignUser, val]);
    } else {
      const data = assignUser.filter((x) => x !== val);
      setAssignUser(data);
    }
  };

  const handleAddQuestion = () => {
    if (inputValue.length >= 1 && !questions.includes(inputValue))
      setQuestions([...questions, inputValue]);
    setInputvalue("");
  };

  const onFeedCheckChange = (e) => {
    if (e.target.checked) {
      setFeed("add_feed");
    } else {
      setFeed("");
    }
  };

  const handleSearchAthlete = (e) => {
    setSearchInput(e.target.value);
  };

  const shouldButtonBeDisabled = () => {
    if (tab == 1) {
      if (valArr.length >= exercises.length * 2) return false;
    } else if (tab == 2) {
      if (adjustmentData.length === exercises.length && assignUser.length > 0)
        return false;
    } else if (tab == 3) {
      if (
        adjustmentData.length === exercises.length &&
        assignUser.length > 0 &&
        workoutNote.length > 0
      ) {
        return false;
      }
    }
    return true;
  };

  const items = [
    {
      key: "1",
      label: `Exercises`,
      children: (
        <div className="exercises-cntnr">
          {exercises?.map(
            (
              {
                adjustment_list,
                exercise_id,
                exercise_name,
                set_list,
                sort_description,
              },
              i
            ) => {
              return (
                <div key={i}>
                  <AssignExercise
                    id={exercise_id}
                    name={exercise_name}
                    set={set_list}
                    adjustment={adjustment_list}
                    description={sort_description}
                    handleChange={handleChange}
                  />
                </div>
              );
            }
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: `Select Athletes`,
      children: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "10px",
          }}
        >
          <Inputfield
            placeholder="Type to search"
            className="login-input m-2"
            onChange={handleSearchAthlete}
          />
          <div className="slct-athletes-cntnr ms-2">
            {athlete
              ?.filter((post) => {
                if (searchInput === "") {
                  return post;
                } else if (
                  post.other_user_name
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
                ) {
                  return post;
                }
              })
              ?.map((x, i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <Checkbox
                      key={i}
                      className="exercise-checkbox"
                      checked={assignUser?.includes(x.other_user_id)}
                      onChange={(e) => onCheckChange(e, x.other_user_id)}
                    >
                      <div>
                        <img
                          src={
                            x.other_user_profile_pic
                              ? x.other_user_profile_pic
                              : AthletePic
                          }
                          alt="athlete-pic"
                          style={{
                            height: "50px",
                            borderRadius: "50%",
                            width: "50px",
                          }}
                        />
                        <span className="athlete-name mx-2">
                          {x.other_user_name}
                        </span>
                      </div>
                    </Checkbox>
                  </div>
                );
              })}
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: `Add Questions & Notes`,
      children: (
        <div
          style={{
            flex: "1 1 auto",
          }}
        >
          <div className="add-notes-input">
            <TextArea
              onChange={(e) => setWorkoutNote(e.target.value)}
              placeholder="Notes"
              autoSize={{ minRows: 5, maxRows: 5 }}
              maxLength="220"
            />

            <Checkbox
              onChange={onFeedCheckChange}
              className="no-accout mt-2 ms-1 add-to-feed"
            >
              Add to Feed
            </Checkbox>
            <div className="add-question-container  mt-4">
              <Inputfield
                placeholder="Enter Question"
                className="login-input"
                value={inputValue}
                onChange={(e) => setInputvalue(e.target.value)}
              />

              <ButtonData type="addbutton" onClick={handleAddQuestion}>
                Add
              </ButtonData>
            </div>
            <div className="slct-exrcse-itm-title ms-1 mt-3">Questions</div>
            {questions?.map((x, i) => {
              return (
                <div key={i} className="question-container mt-2">
                  <div className="slct-exrcse-itm-description">{x}</div>
                  <img
                    onClick={() => removeQuestionHandler(x)}
                    src={DeleteIcon}
                    alt="delete-icon"
                    style={{
                      cursor: "pointer",
                      height: "20px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ),
    },
  ];

  const removeQuestionHandler = (val) => {
    const filterArr = questions.filter((x) => x !== val);
    setQuestions(filterArr);
  };

  const onTabChange = (key) => {
    setTab(key);
  };

  const postWorkout = (w_val) => {
    let obj = {};
    obj = {
      assign_user_id: assignUser.toString(),
      adjustment_data: adjustmentData,
      is_feed: feed,
      workout_id: w_val ? w_val : workoutId,
      workout_note: workoutNote,
      question_data: questions,
    };
    setLoading(true);
    axios
      .post(`assign_workout_to_user`, obj)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setLoading(false);
          setVisible(false);
          toastr.success("Workout Assigned");
          setAssignUser("");
          setFeed(false);
          setAdjustmentData([]);
          setWorkoutNote("");
          setQuestions([]);
          setTab("1");
        } else {
          toastr.error(res?.response?.data?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error?.message);
        setLoading(false);
      });
  }

  const onSubmitData = () => {
    if (tab === "1") {
      onTabChange("2");
    } else if (tab === "2") {
      onTabChange("3");
    } else {
      if (name === "Assign") {
        const fd = new FormData();
        fd.append("workout_title", workoutDetail?.name);
        fd.append("long_desc", workoutDetail?.description);
        fd.append("exercise_id", exercise_ids);
        fd.append("workout_status", "is_playlist");
        axios
          .post("create_workout", fd)
          .then(async (res) => {
            if (res?.status === 200 && res?.data?.responsecode === 1) {
              // setPlaylist([]);
              // setWorkoutId(res?.data?.data?.workout_id);
              postWorkout(res?.data?.data?.workout_id);
            } else {
              toastr.error(res.data.message);
            }
          })
          .catch((error) => {
            toastr.error(error.message);
          });
      } else {
        postWorkout();
      }
    }
  };

  const renderTabBar = (props, DefaultTabBar) => (
    <StickyBox
      offsetTop={0}
      offsetBottom={20}
      style={{
        zIndex: 1,
      }}
    >
      <DefaultTabBar
        {...props}
        style={{
          background: "#FFFFFF",
          fontFamily: "poppins-semibold",
        }}
      />
    </StickyBox>
  );

  const getCoachAthlete = () => {
    axios
      .post(`coach_athlete_list`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setAthlete(res?.data?.data);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  useEffect(() => {
    getCoachAthlete();
  }, []);

  return (
    <>
      <img
        src={ModalCross}
        alt="modal-cross"
        className="modal-cross"
        onClick={() => handleCancel()}
      />
      <div className="assign-workout-modal">
        <p className="modal-title">Assign Workout</p>

        <div className="modal-content">
          <Tabs
            className="tab-container"
            defaultActiveKey={"1"}
            activeKey={tab}
            items={items}
            onChange={onTabChange}
            renderTabBar={renderTabBar}
            tabBarStyle={{ fontFamily: "poppins-semibold" }}
          />
        </div>

        <div className="modal-footer">
          <ButtonData
            type="authButton mt-3"
            loading={loading}
            disabled={shouldButtonBeDisabled()}
            onClick={() => onSubmitData()}
          >
            {tab === "1"
              ? shouldButtonBeDisabled()
                ? "Select Adjustments & Reps"
                : actionButtonText
              : tab === "2"
              ? shouldButtonBeDisabled()
                ? "Select Athletes"
                : actionButtonText
              : tab === "3"
              ? "Assign Workout"
              : actionButtonText}
          </ButtonData>
        </div>
      </div>
    </>
  );
};

export default AssignWorkoutModal;
