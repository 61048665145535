import React, { useEffect, useState } from "react";
import ModalCross from "../../assets/images/icons/icn_close.png";
import profileDp from "../../assets/images/core/dummy-img.png";
import BlueCamera from "../../assets/images/icons/icn_camera_blue.png";
import User from "../../assets/images/icons/icn_profile.png";
import ButtonData from "./button";
import Inputfield from "./Inputfield";
import toastr from "toastr";
import FormModal from "../modals/FormModal";
import axios from "../../helper/api/api_instance";
import { Upload, Form } from "antd";
import { useNavigate } from "react-router-dom";
import { Base_Route } from "../../helper/constant";
import DeleteAccoutModal from "../modals/DeleteAccoutModal";
const EditProfile = ({ handleCancel }) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [state, setState] = useState({});
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const UserProfile = JSON.parse(
    localStorage.getItem("UserProfile")
  ).UserProfile;

  useEffect(() => {
    setState({ imageUrl: UserProfile?.profile_pic });
    setFileList([...fileList]);
    form.setFieldsValue({
      userName: UserProfile?.user_name,
    });
  }, []);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    getBase64(newFileList.slice(-1)[0]?.originFileObj, (imageUrl) =>
      setState({
        imageUrl,
      })
    );
  };

  const props = {
    action: "",
    onChange: handleChange,
    multiple: false,
  };

  const onFinish = (values) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("user_name", values?.userName);
    if (fileList?.length > 0) {
      fd.append(
        "profile_pic",
        fileList?.length === 0 &&
          typeof fileList[fileList.length - 1]?.imageUrl === "string"
          ? ""
          : fileList[fileList.length - 1].originFileObj
      );
    }

    fd.append("user_entity_id", UserProfile?.user_id);

    axios
      .post("edit_user", fd)
      .then(async (res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          localStorage.setItem(
            "UserProfile",
            JSON.stringify({
              UserProfile: res.data.data,
            })
          );
          handleCancel();
          setLoading(false);
        } else {
          toastr.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
    // }
  };

  const deleteAccountHandler = () => {
    axios
      .post(`user_delete_account`)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          localStorage.removeItem("AuthUser");
          localStorage.removeItem("UserProfile");

          navigate(`${Base_Route}`);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  return (
    <>
      {" "}
      <img
        src={ModalCross}
        alt="modal-cross"
        className="modal-cross"
        onClick={() => handleCancel()}
      />
      <p className="modal-title">Edit Profile</p>
      <Form
        name="edit-user"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <div className="profile-main-div d-flex flex-column align-items-center justify-content-center p-4">
          <img
            src={state?.imageUrl ? state?.imageUrl : profileDp}
            alt="profile"
            width="220px"
            height="220px"
            style={{ borderRadius: "50%" }}
          />
          <div
            className="d-flex justify-content-center mt-3"
            style={{ width: "60%" }}
          >
            <Upload
              {...props}
              fileList={fileList}
              accept="image/png, image/jpeg"
              maxCount={1}
            >
              <ButtonData
                type="border-blue-btn profile-btn"
                style={{ width: "110px" }}
                icon={
                  <img
                    src={BlueCamera}
                    alt="blue-camera"
                    style={{ height: "20px" }}
                  />
                }
              >
                Upload
              </ButtonData>
            </Upload>
          </div>
          <Form.Item
            name="userName"
            rules={[
              {
                required: true,
                message: "Please enter user name",
              },
            ]}
          >
            <Inputfield
              prefix={<img alt="" src={User} style={{ height: "20px" }} />}
              placeholder="Enter Name"
              className="login-input mt-4"
            />
          </Form.Item>
        </div>
        <div className="mx-4">
          <ButtonData
            type="assign-btn assign-text mt-4 w-100 px-3"
            style={{ height: "45px" }}
            htmltype="submit"
            loading={loading}
          >
            Save
          </ButtonData>

          <ButtonData
            onClick={() => setDeleteModal(true)}
            type="border-red-btn mt-3 w-100 px-3"
          >
            Delete Account
          </ButtonData>
        </div>
      </Form>
      <FormModal
        visible={deleteModal}
        title={"Delete Account"}
        onCancel={() => setDeleteModal(false)}
        content={
          <DeleteAccoutModal
            deleteAccountHandler={deleteAccountHandler}
            setDeleteModal={setDeleteModal}
            deleteModal={deleteModal}
          />
        }
      />
    </>
  );
};

export default EditProfile;
