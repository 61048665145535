import React from "react";
import ModalCross from "../../assets/images/icons/icn_close.png";
import { Form, Radio } from "antd";
import Inputfield from "./Inputfield";
import { Input } from "antd";
import toastr from "toastr";
import Copy from "../../assets/images/icons/icn_copy.png";
import ButtonData from "./button";
import CopyToClipboard from "react-copy-to-clipboard";
const CreateGroup = ({
  handleCancel,
  onFinish,
  onChange,
  value,
  invite,
  loading,
}) => {
  const { TextArea } = Input;
  return (
    <div className="mt-3">
      <img
        src={ModalCross}
        alt="modal-cross"
        className="modal-cross"
        onClick={() => handleCancel()}
      />
      <p className="m-0 group-type">Group Type</p>
      <Form
        name="modal-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="radioGroup"
          rules={[
            {
              required: true,
              message: "Please select group type",
            },
          ]}
        >
          <Radio.Group
            className=" mt-2"
            onChange={onChange}
            value={value}
            defaultValue={2}
          >
            <Radio
              value={1}
              style={{ color: value === 1 ? "#007CBA" : "#526B78" }}
              className="group-type"
            >
              Private Group
            </Radio>
            <Radio
              value={2}
              style={{ color: value === 2 ? "#007CBA" : "#526B78" }}
              className="group-type"
            >
              Public Group
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter group name",
            },
          ]}
        >
          <Inputfield
            placeholder="Enter Group Name"
            className="modal-field mt-3"
          />
        </Form.Item>
        {value === 1 && (
          <Inputfield
            placeholder="Enter Invite Code"
            className="modal-field mt-3"
            style={{ caretColor: "transparent" }}
            value={invite}
            suffix={
              <CopyToClipboard text={invite}
              onCopy={() => toastr.success("Invite code copied")}
          >
              <img
                className="feed-heart-icon"
                alt=""
                src={Copy}
                style={{ height: "20px" }}
              />
              </CopyToClipboard>
            }
          />
        )}

        <Form.Item
          name="groupDescription"
          rules={[
            {
              required: true,
              message: "Please enter group description",
            },
          ]}
        >
          <TextArea
            className="mt-3 modal-field"
            rows={4}
            placeholder="Group Description"
            maxLength={300}
          />
        </Form.Item>
        <Form.Item name="groupBtn">
          <ButtonData loading={loading} htmltype="submit" type="modal-btn mt-3">
            Create Group
          </ButtonData>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateGroup;
