import React from "react";
import ModalCross from "../../assets/images/icons/icn_close.png";
import tick from "../../assets/images/icons/img_join_group_success.png";

const SuccessModal = ({message, setVisible}) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center flex-column">
        <img src={tick} alt="" style={{ height: "50px", width: "50px" }} />
        <p className="mt-2 modal-title">{message}</p>
      </div>
    </>
  );
};

export default SuccessModal;
