import React, { useState } from "react";
import ButtonData from "./button";
import axios from "../../helper/api/api_instance";
import exerciseImg from "../../assets/images/core/Feed-img.png";
import toastr from "toastr";
import { Typography } from "antd";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackArrow from "../../assets/images/icons/icn_back.png";
import AssignWorkoutModal from "../modals/AssignWorkoutModal";
import LogWorkoutModal from "../modals/LogWorkoutModal";
import StripeModal from "../modals/StripeModal";
import FormModal from "../modals/FormModal";
import Spinner from "./spinner";
import { Base_Route } from "../../helper/constant";
const { Text } = Typography;
const WorkoutDetail = () => {
  const user_type = JSON.parse(localStorage.getItem("UserProfile"))?.UserProfile
    ?.user_type;
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [payment, setPayment] = useState();
  const [workoutDetail, setWorkoutDetail] = useState({});
  const [workoutDetailLoading, setWorkoutDetailLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWorkoutDetail(location?.state?.id);
  }, [location]);

  useEffect(() => {
    checkPayment();
  }, []);

  const checkPayment = () => {
    axios
      .post(`verify_payment`)
      .then((res) => {
        if (res?.status === 200) {
          setPayment(res?.data?.status);
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };
  const getWorkoutDetail = (id) => {
    setWorkoutDetailLoading(true);
    const fd = new FormData();
    fd.append("workout_id", id);
    axios
      .post(`workout_details`, fd)
      .then((res) => {
        setWorkoutDetailLoading(false);
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setWorkoutDetail(res?.data?.data);
        } else {
          setWorkoutDetailLoading(false);
          toastr.error(res.data.message);
        }
      })
      .catch((error) => {
        setWorkoutDetailLoading(false);
        toastr.error(error.message);
      });
  };

  const logWorkoutHandler = (ids) => {
    setLoading(true);
    const fd = new FormData();
    fd.append("exercise_id", ids);
    axios
      .post(`exercise_adjustment_list`, fd)
      .then((res) => {
        if (res?.status === 200 && res?.data?.responsecode === 1) {
          setExercises(res?.data?.data);
          setVisible(true);
          setLoading(false);
        } else {
          toastr.error(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setLoading(false);
      });
  };
  return (
    <>
      {workoutDetailLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <Spinner color="#d9d9d9" />
        </div>
      ) : (
        <>
          <div className="m-2">
            <div className="back-arrow" onClick={() => navigate(-1)}>
              <img
                src={BackArrow}
                alt="black-arrow"
                style={{ height: "12px" }}
              />
              <p className="no-accout mx-2 mt-3">Back</p>
            </div>

            <p className="workout-head mt-2">{workoutDetail?.workout_title}</p>

            <p className="exercise-desc">{workoutDetail?.long_desc}</p>
            <div className="row gx-3 gy-3 ">
              {workoutDetail?.get_exercise_data?.map((x, i) => {
                return (
                  <div className="col-md-6 col-lg-4 col-xl-3" key={i}>
                    <div className="workout-exercise-card cursor-pointer"  onClick={() =>
                      navigate(`${Base_Route}/trufit/exercise-detail`, {
                        state: { id: x?.exercise_id },
                      })}>
                      <img
                        src={
                          x?.exercise_images
                            ? x?.exercise_images[0]?.exercise_image
                            : exerciseImg
                        }
                        style={{
                          height: "55px",
                          width: "80px",
                          borderRadius: "10px",
                        }}
                        alt="exercise"
                      />
                      <div className=" mx-2 d-flex flex-column justify-content-center">
                        <Text
                          className="notes mb-0"
                          style={{
                            width: 200,
                          }}
                          ellipsis={true}
                        >
                          {x?.exercise_name}
                        </Text>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <ButtonData
              onClick={() => logWorkoutHandler(workoutDetail?.exercise_id)}
              type="mt-4 assign-btn"
              style={{ width: "auto" }}
              loading={loading}
            >
              Log Completed Workout
            </ButtonData>
          </div>
        </>
      )}
      <FormModal
        visible={visible}
        onCancel={() => setVisible(false)}
        content={
          user_type === "coach" ? (
            <AssignWorkoutModal
              workoutId={workoutDetail?.workout_id}
              handleCancel={() => setVisible(false)}
              exercises={exercises}
              setVisible={setVisible}
            />
          ) : payment ? (
            <LogWorkoutModal
              workoutId={workoutDetail?.workout_id}
              exercise_ids={workoutDetail?.exercise_id}
              handleCancel={() => setVisible(false)}
              exercises={exercises}
              setVisible={setVisible}
            />
          ) : (
            <StripeModal />
          )
        }
      />
    </>
  );
};

export default WorkoutDetail;
